import Block, { Centraliser } from 'library/components/Block'
import { ButtonSize, ButtonVariant } from 'library/components/Button'

import Card from './components/Card'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type CTAElementType =
  | {
      id: number
      text: string
      link: string
      type: 'link'
    }
  | {
      id: number
      text: string
      link: string
      type: ButtonVariant
      size: ButtonSize
    }

export type ContactCardElementType = {
  id: number
  heading: string
  body?: string
  cta: CTAElementType
}

export type ContactCardsBlockType = {
  scheme: SchemeType
  heading: string
  cards: ContactCardElementType[]
}

const ContactCardsBlock = ({ scheme, heading, cards }: ContactCardsBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const headingType = breakpoint === 'md' ? 'h3' : 'h2'

  return (
    <Block scheme={scheme}>
      <Centraliser type='new'>
        <Container>
          {heading && (
            <Heading type={headingType} tag='h2'>
              {heading}
            </Heading>
          )}

          <CardContainer>
            {cards.map((card) => (
              <Card key={card.id} scheme={scheme} card={card} />
            ))}
          </CardContainer>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default ContactCardsBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  grid-template-rows: auto;
  row-gap: 24px;
  align-items: center;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 48px;
  }
`

const Heading = styled(Text)`
  grid-column: span 2;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 1 / 6;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  row-gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    flex-direction: row;
    column-gap: 24px;
  }
`
