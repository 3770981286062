import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import Image from 'next/image'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type QuoteBlockType = {
  logoSrc: string
  content: string
  authorName: string
  company: string
}

const QuoteBlock = ({ logoSrc, content, authorName, company }: QuoteBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const contentType = ['xs', 'md'].includes(breakpoint) ? 'h4' : 'h3'
  const authorNameType = ['xs', 'md'].includes(breakpoint) ? 'body-small-400' : 'body-400'

  return (
    <Block scheme='light'>
      <Centraliser type='new'>
        <Grid>
          <ImageContainer>
            <Image src={logoSrc} alt='' width={0} height={0} sizes='100vw' draggable={false} />
          </ImageContainer>

          <QuoteContainer>
            <Text tag='h3' type={contentType} align='center'>
              {content}
            </Text>

            <Text tag='p' type={authorNameType}>
              {authorName}, {company}
            </Text>
          </QuoteContainer>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default QuoteBlock

const Grid = styled(BaseGrid)`
  row-gap: 40px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    row-gap: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 48px;
  }
`

const QuoteContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 40px;
  width: 295px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    gap: 32px;
    width: 568px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 560px;
    gap: 48px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 752px;
  }
`

const ImageContainer = styled.div`
  grid-column: 1 / -1;
  width: 140px;
  height: 27px;
  margin: auto;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 164.267px;
    height: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 166px;
    height: 32px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
