import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import Banner from './components/Banner'
import React from 'react'
import Stat from './components/Stat'
import styled from 'styled-components'

export type StatElementType = {
  id: number
  number: number
  withSymbol: boolean
  description: string
}

export type CaseStudyBlockType = {
  prefix: string
  heading: string
  cta: {
    text: string
    link: string
  }
  imageSrc: string
  imageAlt: string
  stats: StatElementType[]
}

const CaseStudy = ({ prefix, heading, cta, stats, imageSrc, imageAlt }: CaseStudyBlockType) => (
  <Block scheme='light'>
    <Centraliser type='new'>
      <Grid>
        <Banner prefix={prefix} heading={heading} cta={cta} imageSrc={imageSrc} imageAlt={imageAlt} />

        <Content>
          {stats.map((stat, index) => (
            <Stat key={`stat-item-${index}`} stat={stat} />
          ))}
        </Content>
      </Grid>
    </Centraliser>
  </Block>
)

export default CaseStudy

const Grid = styled(BaseGrid)`
  row-gap: 32px;
  column-gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    row-gap: 40px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 48px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 16px;

    & > div:last-child {
      border: none;
    }
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    column-gap: 40px;
  }
`
