import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import Button from 'library/components/Button'
import FeatureCard from './components/FeatureCard'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type FeatureCardSize = 'large' | 'medium' | 'small'

export type FeatureCardType = {
  heading: string
  body: string
  desktopImage: string
  mobileImage: string
  size: FeatureCardSize
}

export type FeatureBlockType = {
  heading: string
  body: string
  cta: {
    label: string
    link: string
  }
  cards: FeatureCardType[]
}

const FeatureBlock = ({ heading, body, cta, cards }: FeatureBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  // Filter cards by size
  const largeCards = cards.filter((card) => card.size === 'large')
  const pairedCards = cards.filter((card) => card.size === 'medium' || card.size === 'small')

  // Create pairs of medium and small cards
  const pairedGroups: FeatureCardType[][] = []
  for (let i = 0; i < pairedCards.length; i += 2) {
    const group = pairedCards.slice(i, i + 2)
    pairedGroups.push(group)
  }

  return (
    <Block scheme='light'>
      <Centraliser type='new'>
        <Grid>
          <Heading>
            <Text tag='h1' type={breakpoint === 'md' ? 'h2' : 'h1'}>
              {heading}
            </Text>

            <Text tag='p' type='body-400'>
              {body}
            </Text>

            <Button href={cta.link}>{cta.label}</Button>
          </Heading>

          <Content>
            {largeCards.map((card, index) => (
              <FeatureCard
                key={index}
                heading={card.heading}
                body={card.body}
                imageSrc={breakpoint === 'xs' ? card.mobileImage : card.desktopImage}
                size={card.size}
              />
            ))}

            {pairedGroups.map((group, index) => (
              <FeatureGroup key={index}>
                {group.map((card, subIndex) => (
                  <CardWrapper key={subIndex}>
                    <FeatureCard
                      key={index}
                      heading={card.heading}
                      body={card.body}
                      imageSrc={breakpoint === 'xs' ? card.mobileImage : card.desktopImage}
                      size={card.size}
                    />
                  </CardWrapper>
                ))}
              </FeatureGroup>
            ))}
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default FeatureBlock

const Grid = styled(BaseGrid)`
  row-gap: 48px;
`

const Heading = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 1 / 10;
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    grid-column: 1 / 9;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    grid-column: 2 / 9;
    margin: 0 -20px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  row-gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: auto;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 24px;
  }
`

const FeatureGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    flex-direction: row;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    gap: 24px;
  }
`

const CardWrapper = styled.div`
  display: flex;
  align-self: stretch;
`
