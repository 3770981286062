import { FeatureCardSize } from '../FeatureBlock'
import Flex from 'library/components/Flex'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type FeatureCardTextProps = {
  heading: string
  body: string
  size: FeatureCardSize
}

const FeatureCardText = ({ heading, body, size }: FeatureCardTextProps) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const headingTextType = ['xs', 'sm', 'md'].includes(breakpoint) ? 'body-550' : 'h4'
  const textGap = ['lg'].includes(breakpoint) ? 8 : 0

  return (
    <TextContainer column gap={textGap} $size={size}>
      <Heading tag='h4' type={headingTextType}>
        {heading}
      </Heading>
      <Text tag='p' type='body-small-400' color='charcoal80'>
        {body}
      </Text>
    </TextContainer>
  )
}

export default FeatureCardText

const SIZE_PADDING_CONFIG = {
  large: {
    sm: '24px 8px 0 24px',
    md: '40px 16px 0 40px',
    lg: '48px 24px 0 48px',
  },
  medium: {
    sm: '24.727px 24px 0 24.727px',
    md: '40px 40px 0 40px',
    lg: '48px 120px 0 48px',
  },
  small: {
    sm: '24px 24px 0 24px',
    md: '40px 40px 0 40px',
    lg: '48px 48px 0 48px',
  },
}

const TextContainer = styled(Flex)<{ $size: FeatureCardSize }>`
  padding: 24px 24px 0 24px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    min-width: ${({ $size }) => $size === 'large' && '253px'};
    padding: ${({ $size }) => SIZE_PADDING_CONFIG[$size].sm};
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    min-width: ${({ $size }) => $size === 'large' && '327px'};
    gap: ${({ $size }) => $size === 'large' && '8px'};
    padding: ${({ $size }) => SIZE_PADDING_CONFIG[$size].md};
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    min-width: ${({ $size }) => $size === 'large' && '450px'};
    padding: ${({ $size }) => SIZE_PADDING_CONFIG[$size]?.lg || SIZE_PADDING_CONFIG.small.lg};
  }
`

const Heading = styled(Text)`
  @media ${({ theme }) => theme.newBreakpoints.lg} {
    font-size: 1.125rem;
  }
`
