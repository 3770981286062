import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import React from 'react'
import Text from 'library/components/Text'
import UserTypeTag from './components/UserTypeTag'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type UserType = {
  title: string
}

export type UserTypesBlockType = {
  heading: string
  body: string
  userTypes: UserType[]
}

const UserTypesBlock = ({ heading, body, userTypes }: UserTypesBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const textAlign = breakpoint === 'xs' ? 'left' : 'center'

  return (
    <Block scheme='light'>
      <Centraliser type='new'>
        <Grid>
          <Header>
            <HeadingContainer>
              <Text tag='h2' align={textAlign}>
                {heading}
              </Text>
            </HeadingContainer>

            <Text tag='p' type='body-400' align={textAlign}>
              {body}
            </Text>
          </Header>

          <Content>
            {userTypes.map((userType, index) => (
              <UserTypeTag key={index} title={userType.title} />
            ))}
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default UserTypesBlock

const Grid = styled(BaseGrid)`
  row-gap: 32px;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 48px;
  }
`

const Header = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 492px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 687.805px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 635px;
  }
`

const HeadingContainer = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 324px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 498px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 436px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  row-gap: 16px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  width: 343px;
  margin: auto;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    justify-content: center;
    width: 680px;
    padding: 0px 16px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 960px;
    padding: 0 45px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 1200px;
    padding: 0 108px;
    gap: 16px;
  }
`
