import React, { useState } from 'react'

import CarouselSlide from './CarouselSlide'
import Flex from 'library/components/Flex'
import { HeroSlideElementType } from '../HeroBlock'
import Text from 'library/components/Text'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  slides: HeroSlideElementType[]
  initialSlide: string
}

const CarouselSlider = ({ slides = [], initialSlide }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide)

  const breakpoint = useBreakpoint({ type: 'new' })

  const isMobile = ['xs', 'sm'].includes(breakpoint)
  const slideHeadingType = ['xs', 'sm'].includes(breakpoint)
    ? 'h4'
    : breakpoint === 'md'
    ? 'body-small-550'
    : 'body-550'
  const slideBodyType = ['xs', 'sm'].includes(breakpoint)
    ? 'body-400'
    : breakpoint === 'md'
    ? 'body-small-400'
    : 'body-400'

  return (
    <Wrapper>
      <SlidesContainer>
        <CarouselSlides $isMobile={isMobile}>
          {slides.map((slide, index) => (
            <CarouselSlide
              slide={slide}
              key={slide.id}
              activeSlide={currentSlide}
              index={index}
              headingType={slideHeadingType}
              bodyType={slideBodyType}
            />
          ))}
        </CarouselSlides>

        {slides.length > 1 && !isMobile && (
          <CaptionContainer>
            {slides.map((slide, index) => (
              <CaptionWrapper key={`hero-slide-caption${index}`} onClick={() => setCurrentSlide(slide.id)}>
                <Divider $isActive={currentSlide === slide.id} />
                <Flex column>
                  <Heading type={slideHeadingType} $isActive={currentSlide === slide.id}>
                    {slide.heading}
                  </Heading>
                  <Body type={slideBodyType} $isActive={currentSlide === slide.id}>
                    {slide.body}
                  </Body>
                </Flex>
              </CaptionWrapper>
            ))}
          </CaptionContainer>
        )}
      </SlidesContainer>
    </Wrapper>
  )
}

export default CarouselSlider

const Heading = styled(Text)<{ $isActive: boolean }>`
  color: ${({ $isActive, theme }) => theme.colors[$isActive ? 'white' : 'white40']};
  transition: ${({ theme }) => theme.transitions.color};
  transition-delay: 200ms;
`

const Body = styled(Text)<{ $isActive: boolean }>`
  color: ${({ $isActive, theme }) => theme.colors[$isActive ? 'white60' : 'white40']};
  transition: ${({ theme }) => theme.transitions.color};
  transition-delay: 200ms;
`

const Wrapper = styled.div`
  grid-column: span 2;
  position: relative;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 1 / -1;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    margin: 0 100px;
  }
`

const SlidesContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    gap: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    gap: 40px;
  }
`

const CarouselSlides = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 48px;
  width: 343px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 679.999px;
    gap: 80px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 960px;
    height: 555px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 1200px;
    height: 695px;
  }
`

const CaptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  gap: 24px;
`

const CaptionWrapper = styled.div`
  display: flex;
  width: 370px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  cursor: pointer;
`

const Divider = styled.div<{ $isActive: boolean }>`
  width: 100%;
  height: 2px;
  background-color: ${({ $isActive, theme }) => theme.colors[$isActive ? 'white' : 'white10']};
  transition: ${({ theme }) => theme.transitions.default};
  transition-delay: 200ms;
`
