import { useCallback, useState } from 'react'

const useAccordion = () => {
  const [activeKey, setActiveKey] = useState<number | null>(null)

  const toggleItem = useCallback((activeKey: number) => {
    setActiveKey((prevKey) => (prevKey === activeKey ? null : activeKey))
  }, [])

  return { activeItem: activeKey, toggleItem }
}

export default useAccordion
