import React from 'react'
import { SchemeType } from 'theme'
import styled from 'styled-components'

export type SizeType = keyof typeof SPACER_SIZE_OBJECT

type CustomScheme = SchemeType | 'transparent'

type Props = {
  className?: string
  scheme: CustomScheme
  size: SizeType
}

const Spacer = ({ className, scheme, size }: Props) => (
  <Space
    className={className}
    data-scheme={scheme}
    $size={size}
    $scheme={scheme as SchemeType}
    $isTransparent={scheme === 'transparent'}
  />
)

export default Spacer

const SPACER_SIZE_OBJECT = {
  xl: {
    desktop: 160,
    mobile: 80,
  },
  lg: {
    desktop: 120,
    mobile: 64,
  },
  md: {
    desktop: 80,
    mobile: 48,
  },
  sm: {
    desktop: 64,
    mobile: 32,
  },
}

export const SizeMap = Object.keys(SPACER_SIZE_OBJECT) as SizeType[]

type StyledDiv = { $size: SizeType; $scheme: SchemeType; $isTransparent: boolean }

const Space = styled.div<StyledDiv>`
  width: 100%;
  height: ${({ $size }) => SPACER_SIZE_OBJECT[$size].mobile}px;
  background: ${({ theme, $scheme }) => theme.schemeColor[$scheme]};
  background: ${({ $isTransparent }) => $isTransparent && 'transparent'};

  @media ${({ theme }) => theme.breakpoints.md} {
    height: ${({ $size }) => SPACER_SIZE_OBJECT[$size].desktop}px;
  }
`
