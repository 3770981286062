import React, { ReactNode } from 'react'

import Header from './Header'
import Panel from './Panel'
import { SchemeType } from 'theme'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Props = {
  className?: string
  id: number
  title: string
  activeItem: number | null
  toggleItem: (id: number) => void
  children: ReactNode
}

const AccordionItem = ({ className, id, children, title, activeItem, toggleItem }: Props) => {
  const scheme = useBlockScheme()

  const isActive = activeItem === id

  return (
    <Container className={className} $active={isActive} $scheme={scheme}>
      <Header isActive={isActive} onClick={() => toggleItem(id)}>
        {title}
      </Header>

      <Panel isActive={isActive}>{children}</Panel>
    </Container>
  )
}

export default AccordionItem

const Container = styled.div<{ $active: boolean; $scheme: SchemeType }>`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ $active }) => ($active ? '32px' : 0)};
  border-bottom: 1px solid ${({ theme, $scheme }) => theme.colors[$scheme === 'dark' ? 'white20' : 'charcoal10Solid']};
  transition: ${({ theme }) => theme.transitions.default};
`
