import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import React from 'react'
import Text from 'library/components/Text'
import TradeCard from './components/TradeCard'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type TradeCardType = {
  heading: string
  imageSrc: string
  imageAlt: string
}

export type TradesBlockType = {
  heading: string
  cards: TradeCardType[]
}

const TradesBlock = ({ heading, cards }: TradesBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const isMobile = breakpoint === 'xs'

  return (
    <Block scheme='dark'>
      <Centraliser type='new'>
        <Grid>
          <Heading>
            <Text tag='h2' type={breakpoint === 'md' ? 'h3' : 'h2'} align={isMobile ? 'left' : 'center'}>
              {heading}
            </Text>
          </Heading>

          <Content>
            {cards.map((card) => (
              <TradeCard key={card.imageAlt} card={card} />
            ))}
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default TradesBlock

const Grid = styled(BaseGrid)`
  row-gap: 32px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    row-gap: 40px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    row-gap: 80px;
  }
`

const Heading = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 3 / 11;
    margin: 0 24px;
    row-gap: 40px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    grid-column: 4 / 10;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  align-items: center;
  column-gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 24px;
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    grid-column: 2 / 12;
    column-gap: 16px;
    row-gap: 16px;
    margin: 0 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    column-gap: 24px;
    row-gap: 24px;
    margin: 0 94px;
  }
`
