import Button from 'library/components/Button'
import CaseStudyIcon from '../assets/CaseStudyIcon'
import Flex from 'library/components/Flex'
import Image from 'next/image'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  prefix: string
  heading: string
  cta: {
    text: string
    link: string
  }
  imageSrc: string
  imageAlt: string
}

const Banner = ({ prefix, heading, cta, imageSrc, imageAlt }: Props) => {
  const breakpoint = useBreakpoint({ type: 'new' })
  const prefixType = ['md', 'lg'].includes(breakpoint) ? 'h4' : breakpoint === 'sm' ? 'body-550' : 'body-small-550'

  const headingType = breakpoint === 'lg' ? 'h1' : ['sm'].includes(breakpoint) ? 'h2' : 'h3'

  const handleClick = () => {
    window.open(`${cta.link}`, '_blank')
  }

  return (
    <Container onClick={handleClick}>
      <Content>
        <Flex gap={8} center='vertically'>
          <IconContainer>
            <CaseStudyIcon color='aquamarine' />
          </IconContainer>
          <Text type={prefixType} color='white'>
            {prefix}
          </Text>
        </Flex>

        <HeadingContainer>
          <Text tag='h2' type={headingType} color='white'>
            {heading}
          </Text>

          {cta && (
            <Button
              variant='secondary'
              onClick={(e) => {
                e.stopPropagation()

                handleClick()
              }}
              scheme='dark'
            >
              {cta.text}
            </Button>
          )}
        </HeadingContainer>
      </Content>

      <ImageContainer>
        <Image src={imageSrc} alt={imageAlt || ''} width={0} height={0} sizes='100vw' />
      </ImageContainer>
    </Container>
  )
}

export default Banner

const ImageContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  height: 280px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    height: 340px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    height: 400px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    height: 560px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ${({ theme }) => theme.transitions.default};
  }
`

const Container = styled.div`
  grid-column: 1 / -1;
  column-gap: 16px;
  height: auto;
  min-height: 280px;
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    min-height: 340px;
    column-gap: 24px;
    row-gap: 0;
    align-items: center;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    min-height: 400px;
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    min-height: 560px;
  }

  &:hover {
    ${ImageContainer} > img {
      transform: scale(1.1);
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 24px;
      height: 280px;
      opacity: 0.4;
      background-color: ${({ theme }) => theme.colors.charcoal40};
      transition: ${({ theme }) => theme.transitions.default};

      @media ${({ theme }) => theme.newBreakpoints.sm} {
        min-height: 340px;
      }

      @media ${({ theme }) => theme.newBreakpoints.md} {
        min-height: 400px;
        border-radius: 32px;
      }

      @media ${({ theme }) => theme.newBreakpoints.lg} {
        min-height: 560px;
      }
    }
  }
`

const IconContainer = styled.div`
  display: flex;

  svg {
    width: 40px;
    height: 40px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    svg {
      width: 45px;
      height: 45px;
    }
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 280px;
  gap: 16px;
  padding: 16px;
  text-align: left;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;
  width: 100%;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    padding: 24px;
    min-height: 340px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    min-height: 400px;
    padding: 48px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    min-height: 560px;
    padding: 56px 64px;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    max-width: 450px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    max-width: 500px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    max-width: 900px;
  }
`
