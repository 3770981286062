import Image from 'next/image'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import { TradeCardType } from '../TradesBlock'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'
import useBreakpoint from 'hooks/useBreakpoint'

type TradeCardProps = {
  card: TradeCardType
}

const TradeCard = ({ card }: TradeCardProps) => {
  const scheme = useBlockScheme()
  const breakpoint = useBreakpoint({ type: 'new' })

  const headingType = ['xs', 'sm', 'md'].includes(breakpoint) ? 'body-small-550' : 'body-550'

  return (
    <Container>
      <ImageWrapper $scheme={scheme as SchemeType}>
        <Image src={card.imageSrc} alt={card.imageAlt || ''} width={0} height={0} sizes='100vw' draggable={false} />
      </ImageWrapper>

      <Content>
        <Text tag='p' type={headingType} align='center'>
          {card.heading}
        </Text>
      </Content>
    </Container>
  )
}

export default TradeCard

const Container = styled.div`
  display: flex;
  width: 163px;
  height: 163px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6.667px;
  flex-shrink: 0;
  border-radius: 20px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.navyLight};

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 152px;
    height: 152px;
    gap: 4px;
    border-radius: 16px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 170px;
    height: 170px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 226px;
    height: 226px;
    gap: 8px;
    border-radius: 24px;
  }
`

const ImageWrapper = styled.div<{ $scheme: SchemeType }>`
  width: 163px;
  height: 119px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.navyLight};

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 152px;
    height: 112px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 170px;
    height: 122px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 226px;
    height: 164px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Content = styled.div`
  display: flex;
  padding: 0px 8px 16px 8px;
  justify-content: center;
  align-items: center;
  gap: 6.667px;

  align-self: stretch;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    padding: 0px 8px 12px 8px;
    gap: 6.504px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    padding: 0px 12px 16px 12px;
    gap: 7.596px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    padding: 0px 16px 24px 16px;
    gap: 7.04px;
  }
`
