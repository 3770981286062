import { FeatureCardSize } from '../FeatureBlock'
import FeatureCardText from './FeatureCardText'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

type Props = {
  heading: string
  body: string
  imageSrc: string
  size: FeatureCardSize
}

const SIZE_CONFIG = {
  large: {
    container: {
      sm: { width: undefined },
      md: { width: undefined },
      lg: { width: undefined },
    },
    image: {
      sm: { imageMinHeight: '357px', imageMinWidth: '423px' },
      md: { imageMinHeight: '540px', imageMinWidth: '629px' },
      lg: { imageMinHeight: '640px', imageMinWidth: '742px' },
    },
  },
  medium: {
    container: {
      sm: { width: '392px' },
      md: { width: '550px' },
      lg: { width: '706px' },
    },
    image: {
      sm: { imageMinHeight: '170px', imageMinWidth: undefined },
      md: { imageMinHeight: '240px', imageMinWidth: undefined },
      lg: { imageMinHeight: '300px', imageMinWidth: undefined },
    },
  },
  small: {
    container: {
      sm: { width: '272px' },
      md: { width: '386px' },
      lg: { width: '470px' },
    },
    image: {
      sm: { imageMinHeight: '170px', imageMinWidth: undefined },
      md: { imageMinHeight: '240px', imageMinWidth: undefined },
      lg: { imageMinHeight: '300px', imageMinWidth: undefined },
    },
  },
}

const FeatureCard = ({ heading, body, imageSrc, size }: Props) => (
  <CardContainer $size={size}>
    <FeatureCardText heading={heading} body={body} size={size} />
    <ImageContainer $size={size}>
      <Image src={imageSrc} alt='' width={0} height={0} sizes='100vw' draggable={false} />
    </ImageContainer>
  </CardContainer>
)

export default FeatureCard

const CardContainer = styled.div<{ $size: FeatureCardSize }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 24px;
  gap: 8px;
  background: ${({ theme }) => theme.colors.paleBlue};
  border: 1px solid ${({ theme }) => theme.colors.charcoal5};
  max-width: ${({ $size }) => $size === 'large' && '1200px'};
  min-height: ${({ $size }) => $size === 'large' && '357px'};

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    flex-direction: ${({ $size }) => ($size === 'large' ? 'row' : 'column')};
    width: ${({ $size }) => SIZE_CONFIG[$size].container.sm.width};
    gap: 4px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: ${({ $size }) => SIZE_CONFIG[$size].container.md.width};
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: ${({ $size }) => SIZE_CONFIG[$size].container.lg.width};
    gap: 8px;
  }
`

const ImageContainer = styled.div<{ $size: FeatureCardSize }>`
  height: ${({ $size }) => $size === 'large' && '223px'};
  min-height: 223px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    min-width: ${({ $size }) => SIZE_CONFIG[$size].image.sm.imageMinWidth};
    min-height: ${({ $size }) => SIZE_CONFIG[$size].image.sm.imageMinHeight};
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    min-width: ${({ $size }) => SIZE_CONFIG[$size].image.md.imageMinWidth};
    min-height: ${({ $size }) => SIZE_CONFIG[$size].image.md.imageMinHeight};
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    min-width: ${({ $size }) => SIZE_CONFIG[$size].image.lg.imageMinWidth};
    min-height: ${({ $size }) => SIZE_CONFIG[$size].image.lg.imageMinHeight};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
