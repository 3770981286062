import { ColorType, SchemeType } from 'theme'

import Icon from '../Icon'
import NextLink from 'next/link'
import React from 'react'
import openIntercom from 'helpers/openIntercom'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

export type LinkSize = 'large' | 'small'

type Props = {
  id?: string
  className?: string
  href: string
  scheme?: SchemeType
  children: string
  size?: LinkSize
}

const Link = ({ id, className, href, scheme, children, size = 'large' }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme
  const iconColor = LINK_COLOR_SCHEME[colorScheme].iconColor

  return (
    <StyledLink
      id={id}
      className={className}
      href={href}
      passHref
      $scheme={colorScheme}
      onClick={href === 'intercom' ? openIntercom : undefined}
    >
      <Container>
        <Content $scheme={colorScheme} $size={size}>
          {children}
        </Content>
        <IconContainer>
          <Icon name='right-arrow' color={iconColor} />
        </IconContainer>
      </Container>
    </StyledLink>
  )
}

export default Link

type LinkSchemeType = {
  [S in SchemeType]: {
    color: ColorType
    iconColor: ColorType
    hoverColor: ColorType
    activeColor: ColorType
  }
}

const LINK_COLOR_SCHEME: LinkSchemeType = {
  light: {
    color: 'blue',
    iconColor: 'blue',
    hoverColor: 'blueHover',
    activeColor: 'blueClick',
  },
  dark: {
    color: 'aquamarine',
    iconColor: 'aquamarine',
    hoverColor: 'aquamarineHover',
    activeColor: 'aquamarineClick',
  },
}

type StyledLinkContentProps = {
  $scheme: SchemeType
  $size: LinkSize
}

const StyledLink = styled(NextLink)<{ $scheme: SchemeType }>`
  width: fit-content;

  :focus-visible {
    outline: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
  }
`

const Content = styled.div<StyledLinkContentProps>`
  color: ${({ $scheme, theme }) => theme.colors[LINK_COLOR_SCHEME[$scheme].color]};
  transition: ${({ theme }) => theme.transitions.color};
  ${({ theme, $size }) => theme.typography[$size === 'small' ? 'body-small-550' : 'body-550'].mobile}

  @media ${({ theme }) => theme.breakpoints.md} {
    ${({ theme, $size }) => theme.typography[$size === 'small' ? 'body-small-550' : 'body-550'].desktop}

    :hover {
      color: ${({ $scheme, theme }) => theme.colors[LINK_COLOR_SCHEME[$scheme].hoverColor]};
    }
  }

  :active {
    color: ${({ $scheme, theme }) => theme.colors[LINK_COLOR_SCHEME[$scheme].activeColor]};
  }
`
const IconContainer = styled.div`
  padding-right: 6px;
`

const Container = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;

  ${IconContainer} {
    display: flex;
    align-items: center;

    svg {
      position: relative;
      left: 0.25rem;
      transition: ${({ theme }) => theme.transitions.default};
    }
  }

  :hover {
    ${IconContainer} {
      svg {
        left: 0.5rem;
      }
    }
  }
`
