import Icon from 'library/components/Icon'
import React from 'react'
import Text from 'library/components/Text'
import { UserType } from '../UserTypesBlock'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

const UserTypeTag = ({ title }: UserType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const titleTextType = ['xs', 'sm'].includes(breakpoint)
    ? 'h5'
    : ['lg'].includes(breakpoint)
    ? 'body-550'
    : 'body-small-550'

  return (
    <Container>
      <Icon name='approve' />
      <Text tag='p' type={titleTextType}>
        {title}
      </Text>
    </Container>
  )
}

export default UserTypeTag

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 8px 12px 8px 8px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.charcoal5Solid};

  svg {
    width: 22px;
    height: 22px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    padding: 8px 24px 8px 16px;

    svg {
      width: 27px;
      height: 27px;
    }
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    padding: 12px 28px 12px 20px;
  }
`
