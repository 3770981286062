import { useEffect, useState } from 'react'

import { useInView } from 'framer-motion'

const useCountUpAnimation = (ref: React.RefObject<HTMLElement>, targetEnd: number, duration = 2000) => {
  const [count, setCount] = useState(0)

  const isInView = useInView(ref, { once: true })

  useEffect(() => {
    if (!isInView) return

    const intervalDuration = 1000 / 60
    const totalIntervals = Math.round(duration / intervalDuration)

    let currentInterval = 0
    const counter = setInterval(() => {
      currentInterval++
      const animatedValue = calculateAnimatedValue(currentInterval / totalIntervals)
      setCount(Math.round(targetEnd * animatedValue))

      if (currentInterval === totalIntervals) clearInterval(counter)
    }, intervalDuration)

    return () => clearInterval(counter)
  }, [targetEnd, duration, isInView])

  return count
}

export default useCountUpAnimation

const calculateAnimatedValue = (progressRatio: number) => {
  return progressRatio === 1 ? 1 : 1 - Math.pow(2, -10 * progressRatio)
}
