import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { theme } from 'theme'

interface AvatarProps {
  initials: string
}

const Avatar = ({ initials }: AvatarProps) => {
  const colors = [theme.colors.purple, theme.colors.teal, theme.colors.error40] as string[]

  const getColorFromInitials = (initials: string) => {
    if (initials.length < 2) return theme.colors.purple

    const index = (initials.charCodeAt(0) + initials.charCodeAt(1)) % colors.length
    return colors[index]
  }

  const bgColor = getColorFromInitials(initials)

  return (
    <AvatarContainer $bgColor={bgColor}>
      <Text type='body-small-550' color='white60'>
        {initials}
      </Text>
    </AvatarContainer>
  )
}

export default Avatar

const AvatarContainer = styled.div<{ $bgColor: string }>`
  display: flex;
  width: 46px;
  height: 46px;
  padding: 10.728px 12.754px 11.272px 13.246px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ $bgColor }) => $bgColor};

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    padding: 11px 13px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    padding: 12px 14.5px;
    width: 52px;
    height: 52px;
  }
`
