import Block, { Centraliser } from 'library/components/Block'
import Button, { ButtonSize, ButtonVariant } from 'library/components/Button'

import CarouselSlider from './components/CarouselSlider'
import Flex from 'library/components/Flex'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type HeroButtonElementType = {
  text: string
  link: string
  type: ButtonVariant
  size: ButtonSize
}

export type HeroSlideElementType = {
  id: string
  heading: string
  body: string
  imageSrc: string
  imageAlt?: string
}

export type HeroBlockType = {
  prefix: string
  heading: string
  body: string
  buttons: HeroButtonElementType[]
  slides: HeroSlideElementType[]
}

const HeroBlock = ({ prefix, heading, body, buttons, slides }: HeroBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const isMobile = breakpoint === 'xs'

  return (
    <Block scheme='dark'>
      <Centraliser type='new'>
        <Container>
          <HeroContainer>
            <Flex column gap={16} center>
              <Prefix tag='h4' color='aquamarine'>
                {prefix}
              </Prefix>
              <Heading tag='h1'>{heading}</Heading>
            </Flex>

            <Body type='body-400' tag='p'>
              {body}
            </Body>

            {buttons.length > 0 && (
              <ButtonContainer>
                {buttons.map((item, index) => (
                  <Button
                    key={`button-${index}`}
                    variant={item.type}
                    size={item.size}
                    href={item.link}
                    block={isMobile}
                  >
                    {item.text}
                  </Button>
                ))}
              </ButtonContainer>
            )}
          </HeroContainer>

          <CarouselSlider slides={slides} initialSlide={String(slides[0].id)} />
        </Container>
      </Centraliser>
    </Block>
  )
}

export default HeroBlock
const Prefix = styled(Text)``
const Body = styled(Text)``
const Heading = styled(Text)`
  padding: 0 32px;
  @media ${({ theme }) => theme.newBreakpoints.sm} {
    padding: 0;
  }
`
const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 24px;
  row-gap: 64px;
  padding-bottom: 64px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    row-gap: 80px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    padding-bottom: 120px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    padding-bottom: 160px;
  }
`
const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  grid-column: span 2;
  gap: 16px;
  position: relative;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 2 / 12;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    margin: 0 24px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    grid-column: 3 / 11;
    margin: 0 48px;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    flex-direction: row;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    gap: 16px;
  }
`
