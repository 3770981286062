import Avatar from './Avatar'
import { CardElementType } from '../BlockOfLove'
import Flex from 'library/components/Flex'
import React from 'react'
import Text from 'library/components/Text'
import { format } from 'date-fns'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

type CardProps = {
  cardItem: CardElementType
}

const Card = ({ cardItem }: CardProps) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const descriptionType = breakpoint === 'md' ? 'body-small-550' : 'body-550'

  const initials = (string: string) => {
    const parts = string.split(' ')

    if (parts.length === 1) {
      return string.slice(0, 2)
    }

    return parts[0][0] + parts[1][0]
  }

  return (
    <CardContainer>
      <AvatarContainer>
        <Avatar initials={initials(cardItem.authorName)} />
        <Flex column>
          <Text type='body-small-550' color='white80'>
            {cardItem.authorName}
          </Text>
          <Text type='body-small-400' color='white40'>
            {cardItem.company}
          </Text>
        </Flex>
      </AvatarContainer>

      <Text tag='p' type={descriptionType}>
        {cardItem.content}
      </Text>

      {cardItem.date && (
        <Text tag='p' type='body-small-400' color='white40'>
          {format(new Date(cardItem.date), 'MMMM yyyy')}
        </Text>
      )}
    </CardContainer>
  )
}

export default Card

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 341.5px;
  gap: 12px;
  border-radius: 21.456px;
  background: ${({ theme }) => theme.colors.navyLight};
  border: 1px solid ${({ theme }) => theme.colors.white10};

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 329px;
    gap: 16px;
    border-radius: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 304px;
    gap: 24px;
    padding: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 450px;
    border-radius: 32px;
  }

  p {
    white-space: pre-wrap;
  }
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10.73px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    gap: 12px;
  }
`
