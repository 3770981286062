import Spacer, { SpacerSizeType } from 'library/components/Spacer'

import React from 'react'
import { SchemeType } from 'theme'
import useBreakpoint from 'hooks/useBreakpoint'

type SpacerBlockType = {
  scheme: SchemeType | 'transparent'
  break744: SpacerSizeType
  break1024: SpacerSizeType
  break1280: SpacerSizeType
  break1640: SpacerSizeType
}

const SpacerBlock = ({ scheme, break744, break1024, break1280, break1640 }: SpacerBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const removeNumberFromString = (size: string): SpacerSizeType => {
    return size.replace(/\d+/g, '') as SpacerSizeType
  }

  switch (breakpoint) {
    case 'xs':
      return <Spacer scheme={scheme} size={removeNumberFromString(break744)} />
    case 'sm':
      return <Spacer scheme={scheme} size={removeNumberFromString(break1024)} />
    case 'md':
      return <Spacer scheme={scheme} size={removeNumberFromString(break1280)} />
    case 'lg':
      return <Spacer scheme={scheme} size={removeNumberFromString(break1640)} />
    default:
      return null
  }
}

export default SpacerBlock
