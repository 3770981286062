import Text, { TextType } from 'library/components/Text'
import styled, { css } from 'styled-components'

import { HeroSlideElementType } from '../HeroBlock'
import Image from 'next/image'
import React from 'react'
import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  slide: HeroSlideElementType
  activeSlide: string
  index: number
  headingType: TextType
  bodyType: TextType
}

const CarouselSlide = ({ slide, activeSlide, index, headingType, bodyType }: Props) => {
  const breakpoint = useBreakpoint({ type: 'new' })
  const isMobile = ['xs', 'sm'].includes(breakpoint)
  const isActive = activeSlide === slide.id
  const textAlign = breakpoint === 'xs' ? 'left' : 'center'

  return (
    <Container $zIndex={index} $isMobile={isMobile}>
      <ImageWrapper $isActive={isActive} $isMobile={isMobile}>
        <Image src={slide.imageSrc} alt={slide.imageAlt || ''} width={0} height={0} sizes='100vw' priority />
      </ImageWrapper>

      {isMobile && (
        <Caption>
          <Text type={headingType} align={textAlign}>
            {slide.heading}
          </Text>
          <Text type={bodyType} align={textAlign} color='white60'>
            {slide.body}
          </Text>
        </Caption>
      )}
    </Container>
  )
}

export default CarouselSlide

const Container = styled.div<{ $zIndex: number; $isMobile: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    align-items: center;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    gap: 40px;
    align-items: flex-start;
  }

  ${({ $isMobile, $zIndex }) =>
    !$isMobile &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: ${$zIndex};
    `}
`

const ImageWrapper = styled.div<{ $isActive?: boolean; $isMobile: boolean }>`
  width: 100%;
  position: relative;
  border-radius: 24px;
  overflow: hidden;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 679.99px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 960px;
    border-radius: 32px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 1200px;
  }

  img {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease;

    ${({ $isMobile, $isActive }) =>
      !$isMobile &&
      css`
        opacity: ${$isActive ? 1 : 0};
        transition: ${({ theme }) => theme.transitions.carousel.opacity};
      `}
  }
`

const Caption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 343px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 439px;
    gap: 8px;
  }
`
