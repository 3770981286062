import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import Card from './components/Card'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type CardElementType = {
  authorName: string
  company: string
  content: string
  date?: string
}

type Cards = CardElementType[]

export type BlockOfLoveType = {
  heading: string
  body: string
  cards: Cards[]
}

const BlockOfLove = ({ heading, body, cards }: BlockOfLoveType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  const displayedCards =
    breakpoint === 'xs' ? [cards.flat().slice(0, 3)] : breakpoint === 'sm' ? cards.slice(0, 2) : cards

  return (
    <Block scheme='dark'>
      <Centraliser type='new'>
        <Grid>
          <Header>
            <HeadingContainer>
              <Text tag='h1' type={breakpoint === 'md' ? 'h2' : 'h1'} align='center'>
                {heading}
              </Text>
            </HeadingContainer>

            <Text tag='p' type='body-400' color='white80' align='center'>
              {body}
            </Text>
          </Header>

          <Content>
            {displayedCards.map((card, index) => (
              <CardWrapper key={index}>
                {card.map((cardItem) => (
                  <Card key={cardItem.authorName} cardItem={cardItem} />
                ))}
              </CardWrapper>
            ))}
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default BlockOfLove

const Grid = styled(BaseGrid)`
  row-gap: 48px;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 80px;
  }
`

const Header = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 3 / 11;
    margin: 0 -24px;
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    margin: 0 -31px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    grid-column: 4 / 10;
    margin: 0;
  }
`

const HeadingContainer = styled.div`
  width: 100%;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 330px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 694px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 686px;
  }
`

const Content = styled.div`
  grid-column: 1 / -1;
  display: grid;
  gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    gap: 24px;
  }
`
