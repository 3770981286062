import React, { ReactNode } from 'react'

import styled from 'styled-components'

type Props = {
  isActive: boolean
  children: ReactNode
}

const Panel = ({ isActive, children }: Props) => (
  <Container $active={isActive} onClick={(e) => e.stopPropagation()}>
    <Content>{children}</Content>
  </Container>
)

export default Panel

const Container = styled.section<{ $active: boolean }>`
  display: grid;
  grid-template-rows: ${({ $active }) => ($active ? '1fr' : '0fr')};
  cursor: text;
  transition: ${({ theme }) => theme.transitions.default};
`

const Content = styled.div`
  overflow: hidden;
`
