import Block, { BaseGrid, Centraliser } from 'library/components/Block'

import Image from 'next/image'
import React from 'react'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'

export type CustomerLogoType = {
  imageSrc: string
  imageAlt?: string
}

export type CustomersBlockType = {
  heading: string
  logos: CustomerLogoType[]
}

const CustomersBlock = ({ heading, logos }: CustomersBlockType) => {
  const breakpoint = useBreakpoint({ type: 'new' })

  return (
    <Block scheme='dark'>
      <Centraliser type='new'>
        <Grid>
          <Heading>
            <Text tag='h3' type={breakpoint === 'md' ? 'h4' : 'h3'} align='center'>
              {heading}
            </Text>
          </Heading>

          <Content>
            {logos.length > 0 &&
              logos.map((logo) => (
                <ImageContainer key={logo.imageAlt}>
                  <Image
                    src={logo.imageSrc}
                    alt={logo.imageAlt || ''}
                    width={0}
                    height={0}
                    sizes='100vw'
                    draggable={false}
                  />
                </ImageContainer>
              ))}
          </Content>
        </Grid>
      </Centraliser>
    </Block>
  )
}

export default CustomersBlock

const Grid = styled(BaseGrid)`
  row-gap: 24px;

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 16px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    row-gap: 24px;
  }
`

const Heading = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  grid-column: span 2;
  row-gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  column-gap: 24px;
  flex-wrap: wrap;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: 1 / -1;
    flex-direction: row;
    justify-content: space-between;
  }
`

const ImageContainer = styled.div`
  width: 125px;
  height: 65px;
  display: flex;
  align-items: center;
  opacity: 0.6;
  justify-content: center;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    width: 110px;
    height: 57px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    width: 142px;
    height: 73px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    width: 195px;
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
