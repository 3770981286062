import AccordionButton from 'library/components/AccordionButton'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import styled from 'styled-components'
import { useBlockScheme } from 'library/components/Block'

type Props = {
  isActive: boolean
  onClick: () => void
  children: string
}

const Header = ({ isActive, onClick, children }: Props) => {
  const scheme = useBlockScheme()

  return (
    <Container $active={isActive} $scheme={scheme} onClick={onClick}>
      <Title tag='p' type='body-550'>
        {children}
      </Title>

      <AccordionButton variant={isActive ? 'open' : 'closed'} allowPropagation />
    </Container>
  )
}

export default Header

const Title = styled(Text)`
  transition: ${({ theme }) => theme.transitions.color};
`

const Container = styled.div<{ $active: boolean; $scheme: SchemeType }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: ${({ $active }) => ($active ? '24px' : '16px')};
  padding-bottom: ${({ $active }) => ($active ? '24px' : '16px')};
  cursor: pointer;
  user-select: none;
  transition: ${({ theme }) => theme.transitions.default};

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    gap: 48px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    gap: 88px;
    padding: 24px 0;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    gap: 208px;
  }

  :hover {
    ${Title} {
      color: ${({ theme, $scheme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    }
  }
`
