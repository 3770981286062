import { ColorType, SchemeType } from 'theme'
import React, { MouseEvent } from 'react'

import Icon from '../Icon'
import styled from 'styled-components'
import { useBlockScheme } from '../Block'

type AccordionButtonVariant = 'open' | 'closed'

type Props = {
  id?: string
  className?: string
  variant: AccordionButtonVariant
  scheme?: SchemeType
  allowPropagation?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const AccordionButton = ({ id, className, variant, scheme, allowPropagation, onClick }: Props) => {
  const _scheme = useBlockScheme()

  const colorScheme = scheme || _scheme
  const iconName = variant && (variant === 'open' ? 'minus' : 'plus')
  const iconColor = ACCORDION_BUTTON_COLOR_SCHEME[colorScheme].iconColor

  if (!iconName) return null

  return (
    <Container>
      <Button
        id={id}
        className={className}
        $scheme={colorScheme}
        onClick={(e) => {
          !allowPropagation && e.stopPropagation()

          onClick?.(e)
        }}
      >
        <Icon name={iconName} color={iconColor} />
      </Button>
    </Container>
  )
}

export default AccordionButton

type CarouselButtonSchemeType = {
  [S in SchemeType]: {
    iconColor: ColorType
    background: ColorType
    hoverBackground: ColorType
    activeBackground: ColorType
  }
}

const ACCORDION_BUTTON_COLOR_SCHEME: CarouselButtonSchemeType = {
  light: {
    iconColor: 'charcoal',
    background: 'charcoal5',
    hoverBackground: 'charcoal10',
    activeBackground: 'charcoal10',
  },
  dark: {
    iconColor: 'white',
    background: 'white10',
    hoverBackground: 'white20',
    activeBackground: 'white20',
  },
}

type StyledBtnProps = {
  $scheme: SchemeType
}

const Container = styled.div`
  display: flex;
`

const Button = styled.button<StyledBtnProps>`
  all: unset;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: ${({ theme }) => theme.transitions.background};

  background: ${({ $scheme, theme }) => theme.colors[ACCORDION_BUTTON_COLOR_SCHEME[$scheme].background]};

  :hover {
    background: ${({ $scheme, theme }) => theme.colors[ACCORDION_BUTTON_COLOR_SCHEME[$scheme].hoverBackground]};
  }

  :active {
    background: ${({ $scheme, theme }) => theme.colors[ACCORDION_BUTTON_COLOR_SCHEME[$scheme].activeBackground]};
  }

  :focus-visible::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    border: 2px solid ${({ $scheme, theme }) => theme.colors[$scheme === 'dark' ? 'aquamarine' : 'blue']};
    border-radius: 50%;
  }
`
