import React, { useRef } from 'react'

import Flex from 'library/components/Flex'
import { StatElementType } from '../CaseStudyBlock'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useBreakpoint from 'hooks/useBreakpoint'
import useCountUpAnimation from 'library/blocks/Stats/hook/useCountUpAnimation'

type Props = {
  stat: StatElementType
}

const Stat = ({ stat }: Props) => {
  const breakpoint = useBreakpoint({ type: 'new' })
  const statRef = useRef<HTMLDivElement>(null)
  const currentNumber = useCountUpAnimation(statRef, stat.number, 2500)

  const headingType = ['xs', 'sm'].includes(breakpoint) ? 'h1' : 'h2'
  const descriptionType = ['xs', 'sm', 'md'].includes(breakpoint) ? 'body-small-400' : 'body-400'

  return (
    <Container ref={statRef}>
      <Flex>
        <Text tag='h2' type={headingType}>
          {Intl.NumberFormat('en-US').format(currentNumber)}
        </Text>
        {stat.withSymbol && (
          <Text tag='span' type={headingType}>
            +
          </Text>
        )}
      </Flex>

      <Text tag='p' type={descriptionType}>
        {stat.description}
      </Text>
    </Container>
  )
}

export default Stat

const Container = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-right: none;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-column: span 4;
    padding-right: 16px;
    border-right: 1px solid ${({ theme }) => theme.colors.charcoal20Solid};
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    padding-right: 24px;
    gap: 16px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    padding-right: 40px;
  }
`
