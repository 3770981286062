import Block, { Centraliser } from 'library/components/Block'

import AccordionItem from './components/AccordionItem'
import React from 'react'
import { SchemeType } from 'theme'
import Text from 'library/components/Text'
import styled from 'styled-components'
import useAccordion from 'hooks/useAccordion'

export type AccordionItemElementType = {
  id: number
  question: string
  answer: string
}

export type AccordionBlockType = {
  scheme: SchemeType
  heading: string
  items: AccordionItemElementType[]
}

const AccordionBlock = ({ scheme, heading, items }: AccordionBlockType) => {
  const { activeItem, toggleItem } = useAccordion()

  return (
    <Block scheme={scheme}>
      <Centraliser type='new'>
        <Container>
          {heading && (
            <Heading>
              <Text tag='h2'>{heading}</Text>
            </Heading>
          )}

          <Accordion>
            {items.map((item) => (
              <AccordionItem
                key={item.id}
                id={item.id}
                title={item.question}
                activeItem={activeItem}
                toggleItem={toggleItem}
              >
                <Content tag='p' type='body-400' color={scheme === 'dark' ? 'white' : 'charcoal80'}>
                  {item.answer}
                </Content>
              </AccordionItem>
            ))}
          </Accordion>
        </Container>
      </Centraliser>
    </Block>
  )
}

export default AccordionBlock

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2;
  column-gap: 16px;
  row-gap: 24px;

  @media ${({ theme }) => theme.newBreakpoints.sm} {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 24px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    row-gap: 48px;
  }
`

const Heading = styled.div`
  grid-column: 1 / -1;
`

const Accordion = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
`

const Content = styled(Text)`
  @media ${({ theme }) => theme.newBreakpoints.sm} {
    margin-right: 80px;
  }

  @media ${({ theme }) => theme.newBreakpoints.md} {
    margin-right: 120px;
  }

  @media ${({ theme }) => theme.newBreakpoints.lg} {
    margin-right: 240px;
  }
`
